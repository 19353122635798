import React from 'react';

function DividerLine(props) {
  console.log(props);
  return (
    <>
      <hr />
      <div />
    </>
  );
}

export default DividerLine;
